import { CloseIcon } from "../../../common/functions";

function ViewSettlement(props: any) {
  let data = props.positionInfo["Interest"] || {};
  let dates: any = Object.keys(data);
  let filtered = dates.filter((date: any) => !date.includes("Total"));

  let tableTitles = ["Date", "Amount", "Total"];
  return (
    <div className="edit-info-container-1">
      <div onClick={(event) => props.setSettlementCardDisplay("none")}>
        <CloseIcon classNameInput="rlzd-close-icon" />
      </div>

      <div className="trades-inputs position-container rlzd-trades-info-container-2 rlzd-trades-card">
        <div className="rlzd-trades-titles">
          <table
            id="rlzd-trades"
            style={{
              width: "100%",
            }}
            className="table table-hover table-portfolio table-striped table-trades"
          >
            <tbody>
              <tr className="sticky-top table-header">
                {tableTitles.map((title: string, index: number) => (
                  <td key={index}>{title}</td>
                ))}
              </tr>

              {filtered.map((date: any, index: number) => (
                <tr key={index} className="table-body">
                  <td>{date}</td>
                  <td>{data[date].toLocaleString()}</td>
                  <td>{data[date + " Total"].toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
export default ViewSettlement;
