import { createBrowserRouter } from "react-router-dom";
import CentralizedExcel from "../components/eblot/centralizedExcel";
import SignUp from "../components/auth/signUp";
import Login from "../components/auth/login";
import ErrorPage from "../common/errorPage";
import ViewReport from "../components/reports/portfolio/viewPortfolio";
import UpdateDB from "../components/operations/uploadTrades";
import UpdatePrices from "../components/operations/updatePrices";
import ViewTrades from "../components/reports/viewTrades";
import MufgExcel from "../components/eblot/mufgExcel";
import MufgExcelCDS from "../components/eblot/mufgExcelCDS";
import EditLogs from "../components/operations/editLogs";
import IbExcelFormat from "../components/formatter/ibExcel";
import EmsxExcelFormat from "../components/formatter/emsxExcel";
import ResetPassword from "../components/auth/resetPassword";
import FxExcel from "../components/formatter/fxExcel";
import MufgFxExcel from "../components/formatter/mufgFxExcel";
import UpdatePreviousPrices from "../components/operations/updatePreviousPrices";
import CheckMUFG from "../components/operations/mufgReconcile";
import ViewSummary from "../components/frontOffice/portfolio/viewSummary";
import FundDetails from "../components/operations/fundDetails";
import ViewRisk from "../components/frontOffice/risk/viewRisk";
import ViewPerformers from "../components/frontOffice/performers/viewPerformers";
import ViewRiskReport from "../components/reports/risk/viewRisk";
import CheckNomura from "../components/operations/nomuraReconcile";
import ViewSummaryRisk from "../components/frontOffice/portfolio/viewSummaryExposure";
import FXPosition from "../components/operations/fxPosition";
import NomuraExcel from "../components/eblot/nomuraExcel";
import ViewUsers from "../components/auth/userManagement";
import CheckRlzdPositions from "../components/operations/checkRealizedPositions";
import ViewFactSheetReport from "../components/reports/factSheet/viewFactSheet";
import FactSheetData from "../components/operations/factSheetData";
import ViewFactSheetReportCompare from "../components/reports/factSheet/viewFactSheetCompare";
import Links from "../components/operations/links";
import LinksRedirect, { LinksRedirectMedia } from "../components/operations/linksRedirect";
import ConfirmationExcel from "../components/eblot/confirmationExcel";
import PnlAnalytics from "../components/frontOffice/analytics/pnl";
import User from "../components/auth/user";
import CashReconcile from "../components/operations/cashReconcile";
import EmailUpdate from "../components/operations/emailUpdate";
import Media from "../components/media/main";
import ProcessExcel from "../components/operations/processExcel";
import ViewContacts from "../components/auth/contacts";
import CashFlowExpected from "../components/operations/cashFlowExpected";
import ViewInvestorsTrades from "../components/auth/investors_trades/viewInvestorsTrades";
const router = createBrowserRouter([
  {
    path: "/centralized-blot",
    element: <CentralizedExcel />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/sign-up",
    element: <SignUp />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/view-portfolio",
    element: <ViewReport />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/upload-trades",
    element: <UpdateDB />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/update-prices",
    element: <UpdatePrices />,
    errorElement: <ErrorPage />,
  },

  {
    path: "/view-trades",
    element: <ViewTrades />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/mufg-excel",
    element: <MufgExcel />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/mufg-excel-cds",
    element: <MufgExcelCDS />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/ib-excel",
    element: <IbExcelFormat />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/emsx-excel",
    element: <EmsxExcelFormat />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/fx-excel",
    element: <FxExcel />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/mufg-fx-excel",
    element: <MufgFxExcel />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/update-previous-prices",
    element: <UpdatePreviousPrices />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/edit-logs-corporate-events",
    element: <EditLogs />,
    errorElement: <ErrorPage />,
  },

  {
    path: "/check-mufg",
    element: <CheckMUFG />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/",
    element: <ViewSummary />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/view-exposure-summary",
    element: <ViewSummaryRisk />,
    errorElement: <ErrorPage />,
  },

  {
    path: "/fund-details",
    element: <FundDetails />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/view-risk-summary",
    element: <ViewRisk />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/view-performers",
    element: <ViewPerformers />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/view-risk-report",
    element: <ViewRiskReport />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/error",
    element: <ErrorPage />,
  },
  {
    path: "/check-nomura",
    element: <CheckNomura />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/fx-position",
    element: <FXPosition />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/nomura-excel",
    element: <NomuraExcel />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/users",
    element: <ViewUsers />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/check-live-positions",
    element: <CheckRlzdPositions />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/view-fact-sheet",
    element: <ViewFactSheetReport />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/view-fact-sheet-data",
    element: <FactSheetData />,
    errorElement: <ErrorPage />,
  },

  {
    path: "/view-fact-sheet-mkt",
    element: <ViewFactSheetReportCompare />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/links",
    element: <Links />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/links-redirect",
    element: <LinksRedirect />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/links-redirect-media",
    element: <LinksRedirectMedia />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/confirmation-excel",
    element: <ConfirmationExcel />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/pnl-analytics",
    element: <PnlAnalytics />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/user",
    element: <User />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/cash-reconcile",
    element: <CashReconcile />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/email-update",
    element: <EmailUpdate />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/media",
    element: <Media />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/process-excel",
    element: <ProcessExcel />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/contacts",
    element: <ViewContacts />,
    errorElement: <ErrorPage />,
  }, {
    path: "/cash-flow",
    element: <CashFlowExpected />,
    errorElement: <ErrorPage />,
  },{
    path: "/investors-trades",
    element: <ViewInvestorsTrades />,
    errorElement: <ErrorPage />,
  },
]);

export default router;
