import { useState, useEffect } from "react";
import NavBar from "../../common/navbar";
import { baseUrl, getRequestOptions, handleAuth } from "../../common/cookie";
import Loader from "../../common/loader";
import ChangesView from "./changesView";

function EditLogs() {
  let [logs, setLogs] = useState([]);
  let [filteredLogs, setFilteredLogs] = useState([]);
  let [slicedLogs, setSlicedLogs] = useState([]);
  let tableTitles: any = ["identifier", "date_time", "changes", "edit_note"];
  let [maxLimit, setMaxLimit] = useState(30);
  let [maxLimitBtnDisplay, setMaxLimitBtnDisplay] = useState("block");
  let todayDate: any = new Date();
  let [fromRange, setFromRange] = useState(todayDate);
  let [toRange, setToRange] = useState(todayDate);
  let [changesLogs, setChanges] = useState([]);
  let [changesDisplay, setChangesDisplay] = useState("none");
  slicedLogs = filteredLogs.slice(0, maxLimit);
  const [request, setRequestStatus] = useState(false);

  let [logsType, setLogsType] = useState("errors");
  let url: any = baseUrl + `edit-logs?logsType=${logsType}`;
  let today = new Date(new Date().getTime() + 8 * 60 * 60 * 1000).toISOString().slice(0, 10);

  useEffect(() => {
    fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        setLogs(data);
        setFilteredLogs(data);
        if (data.length <= maxLimit) {
          setMaxLimitBtnDisplay("none");
        } else {
          setMaxLimitBtnDisplay("block");
        }
      });
    setRequestStatus(false);
  }, []);

  async function getEditLogs(logsType: string) {
    setRequestStatus(true);
    setLogsType(logsType);

    let url: any = baseUrl + `edit-logs?logsType=${logsType}`;
    await fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        setLogs(data);
        setFilteredLogs(data);
        setRequestStatus(false);
        if (data.length <= maxLimit) {
          setMaxLimitBtnDisplay("none");
        } else {
          setMaxLimitBtnDisplay("block");
        }
      });
  }
  function onChangeLogsFilter(event: any) {
    setFromRange(new Date());
    setToRange(new Date());
    let searchTerms = event.target.value.toLowerCase().split(" ");

    let filtered = logs.filter((log: any) => {
      // Check if position is truthy to avoid errors when accessing properties
      if (!log) return false;
      // Check if each property exists and includes the searchTerm
      return searchTerms.every((searchTerm: any) => log["identifier"]?.toString().toLowerCase().includes(searchTerm) || log["editNote"]?.toString().toLowerCase().includes(searchTerm) || log["changes"]?.toString().toLowerCase().includes(searchTerm));
    });
    filtered.sort((previous: any, next: any) => new Date(next["dateTime"]).getTime() - new Date(previous["dateTime"]).getTime());
    setFilteredLogs(filtered);
    if (filtered.length <= maxLimit) {
      setMaxLimitBtnDisplay("none");
    } else {
      setMaxLimitBtnDisplay("block");
    }
  }
  function handleIncreaseLimit(event: any) {
    let updatedMaxLimit = maxLimit + 30;
    setMaxLimit(updatedMaxLimit);
    if (logs.length < maxLimit) {
      setMaxLimitBtnDisplay("none");
    }
  }

  function setLogChangesView(event: any, changes: any) {
    try {
      if (logsType.includes("prices")) {
        changes = { ...changes[0], ...changes[1] };
        // Transform the changes object into an array of JSX elements
        const changesArray: any = Object.keys(changes).map((issue) => (
          <p key={issue}>
            {issue} with location {changes[issue].location} did not update. Notional = {changes[issue].notional}{" "}
          </p>
        ));
        // Set the state with the new array
        setChanges(changesArray);
      } else {
        const changesArray = changes.map((position: any, index: any) => <p key={index}>{JSON.stringify(position, null, 2)}</p>);
        setChanges(changesArray);
      }
      setChangesDisplay("block");
    } catch (error) {
      console.log(changes);
      console.log(error);
      setChanges([]);

      setChangesDisplay("block");
    }
  }

  function downloadCSV() {
    // Get the HTML table element
    var table: any = document.getElementById("table-id");

    // Create an empty string for the CSV data
    var csv = "";

    // Loop through each row in the table
    for (var i = 0; i < table?.rows.length; i++) {
      // Loop through each cell in the row
      for (var j = 0; j < table?.rows[i].cells.length; j++) {
        // Add the cell value to the CSV data, surrounded by quotes
        csv += '"' + table?.rows[i].cells[j].textContent + '",';
      }
      // Add a newline character to the end of each row
      csv += "\n";
    }

    // Create a new Blob object with the CSV data
    var blob = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
    });

    // Create a new URL object for the Blob object
    var url = URL.createObjectURL(blob);

    // Create a new link element for the download
    var link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `trades-as-of-${today}-${logsType}.csv`);

    // Trigger the download by clicking the link
    link.click();
  }

  function changeEditLogsRange(event: any) {
    let updatedLogs = logs.filter((log) => {
      let objDate = new Date(log["dateTime"]).getTime();
      return objDate >= new Date(fromRange).getTime() && objDate <= new Date(toRange).getTime();
    });
    setMaxLimit(1000000);
    setFilteredLogs(updatedLogs);
    if (updatedLogs.length <= maxLimit) {
      setMaxLimitBtnDisplay("none");
    } else {
      setMaxLimitBtnDisplay("block");
    }
  }

  if (logs == null) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  } else if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <NavBar />
      <div>
        <div className="calender-input-trades-container">
          <div className="calender-inputs-container">
            <div className="edit-logs-time-picker-container">
              <p className="edit-logs-time-picker-text">From</p>
              <input title="date" type="datetime-local" className="input-calendar edit-logs-time-picker" value={fromRange} onChange={(event: any) => setFromRange(event.target.value)} />
            </div>
            <div className="edit-logs-time-picker-container">
              <p className="edit-logs-time-picker-text">To</p>
              <input title="date" type="datetime-local" className="input-calendar edit-logs-time-picker" value={toRange} onChange={(event: any) => setToRange(event.target.value)} />
            </div>
          </div>
          <button className="btn upload-btn no-flex query-trade-btn" onClick={(event) => changeEditLogsRange(event)}>
            Query
          </button>
        </div>
      </div>
      <div className="row">
        <div className="search-container log-search-container">
          <input
            className="form-control mr-sm-2 filter-trades-search-bar"
            type="search"
            placeholder="Search Trade By BB Ticker or ISIN"
            aria-label="Search"
            onChange={(event) => {
              onChangeLogsFilter(event);
            }}
          />
        </div>
        <div className=" log-container">
          <button className={"btn btn-trades btn-edit-logs " + (logsType == "Edit Position" ? "tab-log-selected" : "")} onClick={(event) => getEditLogs("edit_position")}>
            Edit Position
          </button>
          <button className={"btn btn-trades btn-edit-logs " + (logsType == "Delete Position" ? "tab-log-selected" : "")} onClick={(event) => getEditLogs("delete_position")}>
            Delete Position
          </button>

          <button className={"btn btn-trades btn-edit-logs " + (logsType == "Update Prices" ? "tab-log-selected" : "")} onClick={(event) => getEditLogs("update_prices")}>
            Update Prices
          </button>
          <button className={"btn btn-trades btn-edit-logs " + (logsType == "Update Previous Prices based on MUFG" ? "tab-log-selected" : "")} onClick={(event) => getEditLogs("update_previous_prices_mufg")}>
            Update Previous Prices (MUFG)
          </button>
          <button className={"btn btn-trades btn-edit-logs " + (logsType == "Update Previous Prices based on bloomberg" ? "tab-log-selected" : "")} onClick={(event) => getEditLogs("update_previous_prices_bbg")}>
            Update Previous Prices (Bloomberg)
          </button>

          <button className={"btn btn-trades btn-edit-logs " + (logsType == "Upload Trades" ? "tab-log-selected" : "")} onClick={(event) => getEditLogs("upload_trades")}>
            Upload Trades
          </button>
          <button className={"btn btn-trades btn-edit-logs " + (logsType == "Edit Trade" ? "tab-log-selected" : "")} onClick={(event) => getEditLogs("edit_trade")}>
            Edit Trade
          </button>
          <button className={"btn btn-trades btn-edit-logs " + (logsType == "Recalculate Position" ? "tab-log-selected" : "")} onClick={(event) => getEditLogs("recalculate_position")}>
            Recalculate Position
          </button>
          <button className={"btn btn-trades btn-edit-logs " + (logsType == "Recalculate Position" ? "tab-log-selected" : "")} onClick={(event) => getEditLogs("sink_factor")}>
            Sink
          </button>
          <button className={"btn btn-trades btn-edit-logs " + (logsType == "Recalculate Position" ? "tab-log-selected" : "")} onClick={(event) => getEditLogs("pay_in_kind")}>
            Pay In Kind
          </button>
          <button className={"btn btn-trades btn-edit-logs " + (logsType == "Errors" ? "tab-log-selected" : "")} onClick={(event) => getEditLogs("errors")}>
            Errors
          </button>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          overflowX: "auto",
        }}
        className="table-container-custom"
      >
        <table id="table-id" className="table table-logs table-portfolio table-hover table-striped table-edit-logs">
          <tbody>
            <tr className="sticky-top table-header">
              {tableTitles.map((title: string, index: number) => (
                <td key={index}>{title}</td>
              ))}
            </tr>

            {slicedLogs.map((log: any, index) => (
              <tr key={index} className="table-body">
                {tableTitles.map((title: any, index: any) => (
                  <td key={index}>
                    {title === "changes" ? (
                      <div onClick={(event) => setLogChangesView(event, log[title])} style={{ color: "blue", fontStyle: "italic", cursor: "pointer" }}>
                        {log[title][0] ? log[title][0].toString() + " ..." : ""}
                      </div>
                    ) : title === "identifier" && log[title].includes("Link") ? (
                      <a href={log[title].split("Link: ")[1]}>{log[title].split("/")[log[title].split("/").length - 1]}</a>
                    ) : (
                      log[title]
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="edit-info-container-1" style={{ display: changesDisplay, overflowY: "auto" }} onDoubleClick={(event: any) => setChangesDisplay("none")}>
        <ChangesView setChangesDisplay={setChangesDisplay} changesLogs={changesLogs} />
      </div>
      <button className="view-more-button" style={{ display: maxLimitBtnDisplay }} onClick={(event: any) => handleIncreaseLimit(event)}>
        View More Logs
      </button>
      <button id="download-btn" onClick={downloadCSV} className="btn upload-btn">
        Download CSV
      </button>
    </div>
  );
}
export default EditLogs;
