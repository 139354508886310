import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";

const Compare = ({ data, type, title }: { data: any; type: any; title: string }) => {
  const prepareChartData = (className: string) => {
    const pnlData = data[className][type];
    const dates = Object.keys(pnlData);
    const values = Object.values(pnlData) as number[];

    return {
      labels: dates,
      datasets: [
        {
          label: title,
          data: values,
          backgroundColor: values.map((value) => (value >= 0 ? "green" : "red")),
          borderWidth: 1,
        },
      ],
    };
  };

  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: true,
        text: title,
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              if (type === "unrlzdpnl") {
                label += context.parsed.y.toFixed(2) + "%";
              } else {
                label += new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(context.parsed.y);
              }
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        title: {
          display: true,
          text: title,
        },
        min: type === "unrlzdpnl" ? -100 : undefined,
        max: type === "unrlzdpnl" ? 100 : undefined,
        ticks: {
          callback: function (value: number) {
            if (type === "unrlzdpnl") {
              return value.toFixed(2) + "%";
            }
            return new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(value);
          },
        },
      },
    },
  };

  return (
    <div className="overflow-container">
      <div className="table-responsive">
        {Object.keys(data).map((className, index) => {
          if ((type == "rlzdpnl" && data[className].rlzd != 0) || (type == "unrlzdpnl" && data[className].finalUnits != 0)) {
            return (
              <div key={className} style={{ marginBottom: "40px" }}>
                <div className="auth-name-container">
                  <h1>
                    {className}, Current units: {data[className].finalUnits.toLocaleString()}
                  </h1>
                </div>
                <Bar data={prepareChartData(className)} options={options} />
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default Compare;
