import { useState, useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import { baseUrl, getRequestOptions, handleAuth, postRequestOptions } from "../../../common/cookie";
import axios from "axios";
import NavBar from "../../../common/navbar";
import Loader from "../../../common/loader";
import { CopyableCell } from "../../../common/functions";
import ShowPosition from "../../reports/tools/showPosition";
import EditTrade from "../../reports/tools/editTrade";

function ViewInvestorsTrades() {
  let [trades, setTrades] = useState<any>();
  let [filteredTrades, setFilteredTrades] = useState([]);
  let [updatedTrades, setUpdatedTrades] = useState([]);
  let widerTitles: any = [];

  let tableTitlesTrades: any = [
    "investor_name",
    "sub_class_description",
    "trade_date",
    "investor_mantra_id",

    "id_trade_type",
    "capital",
    "cash_rounded_capital",
    "cash_rounded_settlement_amount",
    "cash_rounded_receivable_payable_amount",
    "purchased_perf_fee_factor",
    "cost_of_inv_red_proceeds",
    "billing_code",
    "id_trade",
    "trade_type_name",
    "trade_sub_type_name",
    "units",
    "sign",
    "total_fees",
    "valuation_date",
    "order_trade_date",
    "valuation",
    "gav_pre_fees",
    "ask",
    "bid",
    "total_perf_fee_factor",
    "valuation_precision",
    "units_precision",
    "units_description",
    "trade_settlement_amount",
    "id_order",
    "method",
    "class_currency",
    "trade_type_order",
    "trade_estimate",
    "admin_client_mantra_id",
    "portfolio_mantra_id",
    "legal_entity_mantra_id",
    "legal_entity_description",
    "class_mantra_id",
    "class_description",
    "sub_class_mantra_id",
    "nominee_mantra_id",
    "investor_description",
    "units_on_int_reports",
  ];
  let unEditableParams = ["id_trade","id_order"];
  let query = new URLSearchParams(useLocation().search);

  let positionObject = null;
  let error = null;

  let [maxTrades, setMaxTrades] = useState(100000);

  let [maxTradesBtnDisplay, setMaxTradesBtnDisplay] = useState("block");

  let [tradeInfoDisplay, setTradeInfoDisplay] = useState("none");
  let [tradeInfo, setTradeInfo] = useState<any>({});
  let [authStatus, setAuthStatus] = useState("");

  const [contextMenuState, setContextMenuState] = useState({
    visible: false,
    x: 0,
    y: 0,
  });
  const contextMenuRef: any = useRef(null);

  const handleContextMenu = useCallback((event: any, trade: any) => {
    event.preventDefault();

    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;

    // Calculate the position of the context menu accounting for the scroll
    const x = event.clientX + scrollX;
    const y = event.clientY + scrollY;

    setTradeInfo(trade);
    setContextMenuState({
      visible: true,
      x: x,
      y: y,
    });
  }, []);

  const handleClick = useCallback((event: any) => {
    // Hide context menu if clicking outside
    if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
      setContextMenuState({ visible: false, x: 0, y: 0 });
    }
  }, []);

  updatedTrades = filteredTrades.slice(0, maxTrades);

  const [request, setRequestStatus] = useState(false);

  let url: any = baseUrl + `investors-trades`;
  let today = new Date(new Date().getTime() + 8 * 60 * 60 * 1000).toISOString().slice(0, 10);
  let [fromRange, setFromRange] = useState(0);
  let [toRange, setToRange] = useState(0);

  useEffect(() => {
    fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        setTrades(data);

        let filtered: any = data.sort((previous: any, next: any) => new Date(next["trade_date"]).getTime() - new Date(previous["trade_date"]).getTime());

        setFilteredTrades(filtered);
        if (filtered.length <= maxTrades) {
          setMaxTradesBtnDisplay("none");
        } else {
          setMaxTradesBtnDisplay("block");
        }
      });
  }, []);

  function onChangeTradeFilter(event: any) {
    setMaxTrades(10000);
    let searchWords = event.target.value.toLowerCase().split(" ");

    // Filter the trades based on the search words
    let filtered = trades.filter((trade: any) => {
      // Convert trade fields to lowercase strings for comparison
      let idTrade = (trade["id_trade"] || "").toString().toLowerCase();
      let investorName = (trade["investor_name"] || "").toString().toLowerCase();
      let investorDescription = (trade["investor_description"] || "").toString().toLowerCase();
      let classId = trade["class_mantra_id"].toString().toLowerCase();
      let investorId = trade["investor_mantra_id"].toString().toLowerCase();

      let tradeDate = new Date(trade["trade_date"]).getTime();
      let fromRangeTimestamp = 0,
        toRangeTimestamp = Infinity;
      if (fromRange) {
        fromRangeTimestamp = new Date(fromRange).getTime();
        toRangeTimestamp = new Date(toRange).getTime();
      }

      // Check if all search words are included in any of the trade fields
      return searchWords.every((word: any) => (idTrade.includes(word) || investorName.includes(word) || investorId.includes(word) || investorDescription.includes(word) || classId.includes(word)) && tradeDate >= fromRangeTimestamp && tradeDate <= toRangeTimestamp);
    });

    filtered.sort((previous: any, next: any) => new Date(next["trade_date"]).getTime() - new Date(previous["trade_date"]).getTime());

    setFilteredTrades(filtered);

    if (filtered.length <= maxTrades) {
      setMaxTradesBtnDisplay("none");
    } else {
      setMaxTradesBtnDisplay("block");
    }
  }
  function handleIncreaseLimit(event: any) {
    let updatedMaxLimit = maxTrades + 100;
    let previousFilteredLength = maxTrades;
    setMaxTrades(updatedMaxLimit);

    if (filteredTrades.length < previousFilteredLength) {
      setMaxTradesBtnDisplay("none");
    }
  }

  async function handleEditTrade(event: any) {
    setRequestStatus(true);
    event.preventDefault();

    let formData: any = new FormData(event.target);

    try {
      let auth: any = await axios.post(baseUrl + "edit-investors-trade", formData, postRequestOptions);

      if (!auth.data.error) {
        // window.location.href = "/view-trades";
        setRequestStatus(false);
        setTradeInfoDisplay("none");
      } else {
        setAuthStatus(auth.data.error);
        setRequestStatus(false);
      }
    } catch (error) {
      setAuthStatus("error");
    }
  }
  async function handleDeleteTrade(event: any) {
    setRequestStatus(true);
    event.preventDefault();

    let formData: any = new FormData();

    formData.append("id_trade", tradeInfo["id_trade"]);
    let confirm = window.confirm("Confirm delete trade " + tradeInfo["id_trade"] + " on date " + tradeInfo["trade_date"]);
    if (confirm) {
      try {
        let auth: any = await axios.post(baseUrl + "delete-investors-trade", formData, postRequestOptions);

        if (!auth.data.error) {
          // window.location.href = "/view-trades";
          setRequestStatus(false);
        } else {
          setRequestStatus(false);
          window.alert(`${auth.data.error}`);
        }
      } catch (error) {
        setAuthStatus("error");
      }
    }
    setRequestStatus(false);
  }

  function downloadCSV() {
    // Get the HTML table element
    var table: any = document.getElementById("table-id");

    // Create an empty string for the CSV data
    var csv = "";

    // Loop through each row in the table
    for (var i = 0; i < table?.rows.length; i++) {
      // Loop through each cell in the row
      for (var j = 0; j < table?.rows[i].cells.length; j++) {
        // Add the cell value to the CSV data, surrounded by quotes
        csv += '"' + table?.rows[i].cells[j].textContent + '",';
      }
      // Add a newline character to the end of each row
      csv += "\n";
    }

    // Create a new Blob object with the CSV data
    var blob = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
    });

    // Create a new URL object for the Blob object
    var url = URL.createObjectURL(blob);

    // Create a new link element for the download
    var link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `investors-trades-as-of-${today}.csv`);

    // Trigger the download by clicking the link
    link.click();
  }
  function changeEditTradesRange(event: any) {
    let updatedLogs = trades.filter((trade: any) => {
      let objDate = new Date(trade["trade_date"]).getTime();
      return objDate >= new Date(fromRange).getTime() && objDate <= new Date(toRange).getTime();
    });
    setMaxTrades(1000000);
    setFilteredTrades(updatedLogs);
    if (updatedLogs.length <= maxTrades) {
      setMaxTradesBtnDisplay("none");
    } else {
      setMaxTradesBtnDisplay("block");
    }
  }

  function cancelTradeInfo(event: any) {
    setTradeInfoDisplay("none");
    setAuthStatus("");
  }

  if (trades == null) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  } else if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <NavBar />
      <div className="trades-inputs">
        <div className="col-4 search-container">
          <input
            className="form-control mr-sm-2 filter-trades-search-bar"
            type="search"
            placeholder="Search Trade By BB Ticker or ISIN"
            aria-label="Search"
            onChange={(event) => {
              onChangeTradeFilter(event);
            }}
          />
        </div>
        <div className="calender-input-trades-container">
          <div className="calender-inputs-container">
            <div className="edit-logs-time-picker-container">
              <p className="edit-logs-time-picker-text">From</p>
              <input title="date" type="datetime-local" className="input-calendar edit-logs-time-picker" value={fromRange} onChange={(event: any) => setFromRange(event.target.value)} />
            </div>
            <div className="edit-logs-time-picker-container">
              <p className="edit-logs-time-picker-text">To</p>
              <input title="date" type="datetime-local" className="input-calendar edit-logs-time-picker" value={toRange} onChange={(event: any) => setToRange(event.target.value)} />
            </div>
          </div>
          <button className="btn upload-btn no-flex query-trade-btn" onClick={(event) => changeEditTradesRange(event)}>
            Query
          </button>
        </div>
      </div>

      <div
        style={{
          width: "500%",
          // overflowX: "auto",
        }}
        className="table-container-custom"
        onClick={handleClick}
      >
        <table
          id="table-id"
          style={{
            width: "500%",
          }}
          className="table table-hover table-portfolio table-striped table-trades table-trades-custom"
        >
          <tbody>
            <tr className="sticky-top table-header">
              {tableTitlesTrades.map((title: string, index: number) => (
                <td key={index} className={widerTitles.includes(title) ? "wider-table-cell" : ""}>
                  {title}
                </td>
              ))}
            </tr>

            {updatedTrades.map((trade: any[], index: number) => (
              <tr key={index} className="table-body" onContextMenu={(event) => handleContextMenu(event, trade)}>
                {tableTitlesTrades.map((title: string, index: number) => (
                  <CopyableCell key={index} text={isFinite(trade[tableTitlesTrades[index]]) && parseInt(trade[tableTitlesTrades[index]]) ? parseInt(trade[tableTitlesTrades[index]]).toLocaleString() : trade[tableTitlesTrades[index]]} />
                ))}
              </tr>
            ))}
            {contextMenuState.visible && (
              <div
                ref={contextMenuRef}
                style={{
                  position: "absolute",
                  top: contextMenuState.y,
                  left: contextMenuState.x,
                }}
                className="context-menu-container"
              >
                <div className="context-menu">
                  <div className="context-menue-row">
                    <p className="context-menu-text" onClick={(event) => setTradeInfoDisplay("block")}>
                      Edit Trade
                    </p>
                  </div>
                </div>
                <hr className="hr" />
                <div className="context-menu">
                  <div className="context-menue-row">
                    <p className="context-menu-text" onClick={(event) => handleDeleteTrade(event)}>
                      Delete Trade
                    </p>
                  </div>
                </div>
              </div>
            )}
          </tbody>
        </table>
      </div>
      {positionObject ? <ShowPosition position={positionObject} /> : ""}
      <button className="view-more-button" style={{ display: maxTradesBtnDisplay }} onClick={(event: any) => handleIncreaseLimit(event)}>
        View More Trades
      </button>
      <button id="download-btn" onClick={downloadCSV} className="btn upload-btn">
        Download CSV
      </button>
      <div className="edit-info-container-1" style={{ display: tradeInfoDisplay }} onDoubleClick={(event: any) => cancelTradeInfo(event)}>
        <EditTrade trade={tradeInfo} authStatus={authStatus} unEditableParams={unEditableParams} editTableTitles={tableTitlesTrades} handleEditTrade={handleEditTrade} cancelTradeInfo={cancelTradeInfo} />
      </div>
    </div>
  );
}
export default ViewInvestorsTrades;
