import { useState, useEffect } from "react";
import Loader from "../../common/loader";
import NavBar from "../../common/navbar";
import { handleAuth, postRequestOptions, getRequestOptions, baseUrl } from "../../common/cookie";
import axios from "axios";

function CashFlowExpected() {
  let [error, setError] = useState<any>({});
  let [errorText, setErrorText] = useState<any>("");

  let url: any = baseUrl + `previous-collections`;
  let [dates, setCollectionDates]: any = useState([]);
  useEffect(() => {
    fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        setCollectionDates(data);
      });
  }, []);

  async function handleSubmit(event: any) {
    event.preventDefault();
    setRequestStatus(true);

    // handle file processing here
    const form: any = document.getElementById("reconcile-cash");
    const formData = new FormData(form);

    try {
      setError({});
      setErrorText("");
      let url = baseUrl + "cash-flow";
      let action: any = await axios.post(url, formData, postRequestOptions);
      handleAuth(action);
      if (action.data.error) {
        setError(action.data.error);
      } else {
        console.log(action);
        setTimeout(() => {}, 2000);
        window.open(action.data);
      }

      setRequestStatus(false);
    } catch (error) {
      console.error("Error:", error);

      setRequestStatus(false);
    }
  }

  const [request, setRequestStatus] = useState(false);

  if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <NavBar />
      <div className="title">
        <h1>Expected Cash Flow for next 12 months</h1>
      </div>
      <div className="card-body card row xlsx-card">
        <form onSubmit={(event) => handleSubmit(event)} encType="multipart/form-data" id="reconcile-cash">
          <h3>Select the portfolio's date</h3>

          <select className="select-collection-date" name="collectionDate">
            {dates.map((date: any, index: any) => (
              <option key={index} value={date}>
                {date}
              </option>
            ))}
          </select>
        
          <h4 className="error-text">{errorText ? errorText : ""}</h4>

          <button type="submit" className="btn upload-btn">
            Generate
          </button>
        </form>
      </div>
      <h4 className="error-text">{errorText ? errorText : ""}</h4>
    </div>
  );
}

export default CashFlowExpected;
